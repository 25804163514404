import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Editor } from 'primeng/editor';
import { ConfirmationService, MessageService } from 'primeng/api';

import * as feather from 'feather-icons';
import { WsColmenaGeneralService } from 'src/app/providers/ws-colmena-general.service';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import Quill from 'quill';
import { QuillNotesServies } from 'src/app/providers/QuillNotesServies';
import { Subscription, debounceTime } from 'rxjs';


@Component({
  selector: 'app-modal-notes',
  templateUrl: './modal-notes.component.html',
  styleUrl: './modal-notes.component.css',
  providers: [MessageService, ConfirmationService]
})
export class ModalNotesComponent implements AfterViewInit {

  @ViewChild('editor') editor!: Editor;
  @Input() visible: boolean = false;
  @Input() cod_contacto_note: number = 0;

  //public quill  = null;


  faSpinner = faSpinner;
  faCircleNotch = faCircleNotch;

  public bottomri: string = 'center';
  public NoteComponentRegister: string = '';
  public borrador: string = '';
  public guardando: boolean = false;
  public procesando: boolean = false;
  public LoadVisibleSaves: boolean = false;
  public editorNotesHabilitado: boolean = false;

  private codNoteTemp: number = 0;

  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() modalClosed: EventEmitter<void> = new EventEmitter<void>();

  private contentChangeSubscription: Subscription | null = null;

  public timer: any;

  constructor(
    private elementRef: ElementRef,
    public ServicioGeneral: WsColmenaGeneralService,
    private messageService: MessageService,
    private quillService:QuillNotesServies,
    private confirmationService: ConfirmationService,
  ) {
    this.renderizarIconos();
    this.bottomri = 'center';

    

  }

  onCloseDialog() {
    this.visibleChange.emit(false);
    this.modalClosed.emit();
  }

  onDialogShow() {
    
    const opctions = {
      modules:{
        toolbar:true,
      },
      placeholder:'Digita la información que necesitas guardar en la nota',
      theme:'snow'
    }

    const quill = this.quillService.initializeQuill('#QuillEditoNote', opctions);

    quill.on('editor-change', (eventName: any, ...args: any[]) => {
      if(eventName === 'text-change'){
        this.guardando = true;
        this.LoadVisibleSaves = true;
      } 
    });

    // Subscribe to content changes with debounce
    this.contentChangeSubscription = this.quillService.getContentChanges()
      .pipe(debounceTime(2000)) // Adjust debounce time as needed
      .subscribe(content => {
        
        

        if(content !== '<p><br></p>'){
          this.NoteComponentRegister = content
          this.addTemporalNote();
        }else{
          console.log('tamaño cero');
          this.guardando = false;
          this.NoteComponentRegister = '';
          this.LoadVisibleSaves = false;
        }

        
      });


    this.ServicioGeneral.getTemporalNote(Number(localStorage.getItem('id_usuario')), Number(localStorage.getItem('id_empresa'))).then((NotesTempRespGet: any) => {

      if(NotesTempRespGet.content !== null){
        this.NoteComponentRegister = NotesTempRespGet.content;
        quill.clipboard.dangerouslyPasteHTML(NotesTempRespGet.content);
        this.codNoteTemp = NotesTempRespGet.id;
        var length = quill.getLength();
        quill.setSelection(length, 0);
      }else{
        setTimeout(() => {
          quill.focus();
        }, 0);
      }
      
    });

  }

  /*
  guardarBorrador() {
    this.borrador = this.NoteComponentRegister; // Guardar el contenido del editor como borrador
    console.log(this.borrador);
    //this.messageService.add({severity:'success', summary:'Borrador guardado', detail:'El borrador se ha guardado automáticamente.'});
  }*/

  

  onTextChange() {
    this.guardando = true;
  }

  ngAfterViewInit() {}

  addTemporalNote(): void {
    const TempNotes = {
      id_empresa: Number(localStorage.getItem('id_empresa')),
      id: this.codNoteTemp,
      content: this.NoteComponentRegister,
      id_usuario: Number(localStorage.getItem('id_usuario')),
    };

    if (this.codNoteTemp === 0) {
      this.ServicioGeneral.addTemporalNote(TempNotes).then(
        (NotesTempResp: any) => {
          this.codNoteTemp = NotesTempResp.dato1;
          this.guardando = false;
          this.renderizarIconos();
        }
      );
    } else {
      this.ServicioGeneral.updateTemporalNote(TempNotes).then(
        (NotesTempResp: any) => {
          this.guardando = false;
          this.renderizarIconos();
        }
      );
    }
  }

  deleteTempNotes() {
    
    this.NoteComponentRegister = '';
    this.ServicioGeneral.deleteTemporalNote(
      this.codNoteTemp,
      Number(localStorage.getItem('id_usuario')),
      Number(localStorage.getItem('id_empresa'))
    ).then((NotesTempResp: any) => {
      //console.log(NotesTempResp);
      this.clearEditor();
      this.LoadVisibleSaves = false;
      this.codNoteTemp = 0;
    });
  }

  clearEditor(): void {
    this.quillService.clearContent();
  }

  addNotes(): void {


    const quillSave = this.quillService.getQuillInstance();

    console.log(quillSave?.getSemanticHTML());
    console.log(quillSave?.getText());

    this.procesando = true;
    this.editorNotesHabilitado = true;

    const notes = {
      id_empresa: Number(localStorage.getItem('id_empresa')),
      id_usuario: Number(localStorage.getItem('id_usuario')),
      id_contacto:this.cod_contacto_note,
      txt_titulo: '',
      txt_contenido_html: quillSave?.getSemanticHTML(),
      txt_contenido_texto: quillSave?.getText(),
      nom_tipo_nota_id: '',
    };

    this.ServicioGeneral.addNotesContact(notes).then((NotesResp: any) => {
      if(NotesResp.cod === 7){
        this.procesando = false;
        this.editorNotesHabilitado = false;
        this.onCloseDialog();
        this.deleteTempNotes();
        this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'La nota se ha guardado exitosamente.' });
        this.NoteComponentRegister = '';
      }else{
        console.log(NotesResp);
      }
    });
  }

  confirmDeleteTempo(event: Event) {
    this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: 'Are you sure you want to proceed?',
        icon: 'pi pi-exclamation-triangle',
        
        accept: () => {
           this.deleteTempNotes();
        },
        reject: () => {
           
        }
    });
}

  renderizarIconos() {
    setTimeout(() => {
      const elementosIcono =
        this.elementRef.nativeElement.querySelectorAll('.icon_button'); // Reemplaza '.feather-icon' por el selector de tus iconos
      elementosIcono.forEach((icono: any) => {
        feather.replace({
          // Reemplaza feather.replace con el método que utilizas para renderizar tus iconos Feather
          target: icono,
        });
      });
    }, 0);
  }
}
