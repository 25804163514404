import { Component } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { WsColmenaLoginService } from 'src/app/providers/ws-colmena-login.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [MessageService],
})
export class LoginComponent {

  loginForm: FormGroup;
  arrayPosts: any;
  loading: boolean = false;

  validation_messages = {
    usuario: [{ type: 'required', message: 'Debe ingresar usuario' }],
    password: [{ type: 'required', message: 'Debe ingresar la clave' }],
  };

  conexionInestable: boolean = true;
  hidden = false;
  colorFondoLayoutNews: string = '';
  conexionMsj: string = '';

  constructor(
    private router: Router,
    private LoginServices: WsColmenaLoginService,
    private messageService: MessageService,
  ) {
    this.loginForm = new FormGroup({
      usuario: new FormControl('', Validators.compose([Validators.required])),
      password: new FormControl('', Validators.compose([Validators.minLength(5), Validators.required])),
    });
  }

  ngOnInit(): void {
    this.verificarEstabilidadVelocidadConexion();
  }

  async doLogin() {
    if (this.loginForm.invalid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Complete todos los campos obligatorios'
      });
      return;
    }

    this.loading = true;
    try {
      const response = await this.LoginServices.LoginColmena(this.loginForm.value);
      this.arrayPosts = response;

      if (this.arrayPosts.cod === 7) {
        this.storeSessionData(this.arrayPosts);
        this.router.navigate(['/home']);
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Verificar',
          detail: this.arrayPosts.desc
        });
      }
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Error de conexión'
      });
    } finally {
      this.loading = false;
    }
  }

  private storeSessionData(response: any) {
    // Convertir `dato1` de string JSON a objeto si es necesario
    let parsedDato1;
    try {
      parsedDato1 = JSON.parse(response.dato1);
    } catch (error) {
      console.error("Error al parsear dato1: ", error);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'No se pudo procesar la respuesta del servidor.'
      });
      return;
    }

    if (parsedDato1 && parsedDato1.empresa && parsedDato1.empresa.length > 0) {
      const empresa = parsedDato1.empresa[0];
      localStorage.setItem('sessionData', JSON.stringify(response));
      localStorage.setItem('id_empresa', empresa.id_empresa);
      localStorage.setItem('razon_social', empresa.razon_social);
      localStorage.setItem('estado_empresa', empresa.estado_empresa);
      localStorage.setItem('nombres', empresa.nombres);
      localStorage.setItem('apellidos', empresa.apellidos);
      localStorage.setItem('correo', empresa.correo);
      localStorage.setItem('id_usuario', empresa.id_registro);
      localStorage.setItem('id_persona', empresa.id_persona);
     // localStorage.setItem('logo', empresa.logo);
      localStorage.setItem('token', response.token);
    }
  }

  async verificarEstabilidadVelocidadConexion(): Promise<void> {
    try {
      this.mostrarMensaje('CONEXION A INTERNET INESTABLE', 'red');
      await this.sleep(2000);
      this.mostrarMensaje('REESTABLECIENDO CONEXION', 'orange');
      await this.sleep(2000);
      this.mostrarMensaje('CONEXION A INTERNET REESTABLECIDA', 'green');
      await this.sleep(2000);
      this.hidden = true;
    } catch (error) {
      console.error('Error al verificar la estabilidad y velocidad de la conexión:', error);
    }
  }

  private mostrarMensaje(mensaje: string, colorFondo: string): void {
    this.conexionMsj = mensaje;
    this.colorFondoLayoutNews = colorFondo;
  }

  private sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
