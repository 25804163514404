import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // <-- Importante para [(ngModel)]

import { GestionComercialRoutingModule } from './gestion-comercial-routing.module';
import { RutasComponent } from './pages/rutas.component'; 

// PrimeNG
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  declarations: [
    RutasComponent
  ],
  imports: [
    CommonModule,
    FormsModule,             
    GestionComercialRoutingModule,
    TableModule,
    DialogModule,
    ButtonModule,
    CardModule,
    DropdownModule
  ]
})
export class GestionComercialModule { }
