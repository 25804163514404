<div class="hk-menu" id="menu-sidebar">
  <!-- Brand -->
  <div class="menu-header">
    <span>
      <a class="navbar-brand" href="../home">
        <img  class="brand-img img-fluid isotipo" src="../../../assets/images/logos/isotipo_primary_font_blan.svg" alt="brand"
          style="width: 60px;" />
        <img class="logo-completo brand-img img-fluid" src="../../../assets/images/logos/logo_primary_font_blan.svg" alt="brand"
          style="width: 190px;" />
      </a>
      <button class="btn btn-icon btn-rounded btn-flush-dark flush-soft-hover navbar-toggle">
        <span class="icon">
          <span class="svg-icon fs-5">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-bar-to-left" width="24"
              height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
              stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <line x1="10" y1="12" x2="20" y2="12"></line>
              <line x1="10" y1="12" x2="14" y2="16"></line>
              <line x1="10" y1="12" x2="14" y2="8"></line>
              <line x1="4" y1="4" x2="4" y2="20"></line>
            </svg>
          </span>
        </span>
      </button>
    </span>
  </div>
  <!-- /Brand -->

  <!-- Main Menu -->
  <div data-simplebar class="sidebar-body">
    <div class="menu-content-wrap">
      <div class="menu-group">
        <ul class="navbar-nav flex-column">
          <li class="nav-item active">
            <a class="nav-link" routerLink="/home">
              <span class="nav-icon-wrap">
                <span class="svg-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-template" width="24"
                    height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                    stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <rect x="4" y="4" width="16" height="4" rx="1" />
                    <rect x="4" y="12" width="6" height="8" rx="1" />
                    <line x1="14" y1="12" x2="20" y2="12" />
                    <line x1="14" y1="16" x2="20" y2="16" />
                    <line x1="14" y1="20" x2="20" y2="20" />
                  </svg>
                </span>
              </span>
              <span class="nav-link-text">Principal</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="menu-gap"></div>
      <div class="menu-group">
        <div class="nav-header">
          <span>Apps</span>
        </div>
        <ul class="navbar-nav flex-column">
          <li class="nav-item">
            <a class="nav-link" href="javascript:void(0);" data-bs-toggle="collapse" data-bs-target="#dash_chat">
              <span class="nav-icon-wrap">
                <span class="svg-icon">
                  <fa-icon class="icon icon-tabler icon-tabler-message-dots icon-menu" [icon]="faTasksAlt"></fa-icon>
                </span>
              </span>
              <span class="nav-link-text">Gestor de Tareas</span>
              <span class="angle-down badge badge-sm badge-soft-pink ms-auto">
                <fa-icon class="icon icon-tabler icon-tabler-message-dots icon-menu-left"
                  [icon]="faAngleDown"></fa-icon>
              </span>
            </a>
            <ul id="dash_chat" class="nav flex-column collapse  nav-children">
              <li class="nav-item">
                <ul class="nav flex-column">
                  <li class="nav-item">
                    <a class="nav-link" routerLink="/calendar"><span class="nav-link-text">Calendario</span></a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" routerLink="/tarea"><span class="nav-link-text">Tareas</span></a>
                  </li>
                  <!-- <li class="nav-item">
                      <a class="nav-link" routerLink="/meta"><span class="nav-link-text">Metas</span></a>
                    </li> -->
                </ul>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/inbox">
              <span class="nav-icon-wrap">
                <span class="svg-icon">
                  <fa-icon class="icon icon-tabler icon-tabler-message-dots icon-menu" [icon]="faInbox"></fa-icon>
                </span>
              </span>
              <span class="nav-link-text">Mail</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="javascript:void(0);" data-bs-toggle="collapse" data-bs-target="#dash_contact">
              <span class="nav-icon-wrap">
                <span class="svg-icon">
                  <fa-icon class="icon icon-tabler icon-tabler-message-dots icon-menu" [icon]="faContactBook"></fa-icon>
                </span>
              </span>
              <span class="nav-link-text">Contactos</span>
              <span class="angle-down badge badge-sm badge-soft-pink ms-auto">
                <fa-icon class="icon icon-tabler icon-tabler-message-dots icon-menu-left"
                  [icon]="faAngleDown"></fa-icon>
              </span>
            </a>
            <ul id="dash_contact" class="nav flex-column collapse  nav-children">
              <li class="nav-item">
                <ul class="nav flex-column">
                  <li class="nav-item">
                    <a class="nav-link" routerLink="/prospectos"><span class="nav-link-text">Prospectos</span></a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="javascript:void(0);" data-bs-toggle="collapse" data-bs-target="#dash_file">
              <span class="nav-icon-wrap">
                <span class="svg-icon">
                  <fa-icon class="icon icon-tabler icon-tabler-message-dots icon-menu"
                    [icon]="faMoneyCheckDollar"></fa-icon>
                </span>
              </span>
              <span class="nav-link-text">Ventas</span>
              <span class="angle-down badge badge-sm badge-soft-pink ms-auto">
                <fa-icon class="icon icon-tabler icon-tabler-message-dots icon-menu-left"
                  [icon]="faAngleDown"></fa-icon>
              </span>
            </a>
            <ul id="dash_file" class="nav flex-column collapse  nav-children">
              <li class="nav-item">
                <ul class="nav flex-column">
                  <li class="nav-item">
                    <a class="nav-link" routerLink="/products"><span class="nav-link-text">Productos</span></a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" routerLink="/cotizacion"><span class="nav-link-text">Cotizacion</span></a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" routerLink="/monitor"><span class="nav-link-text">Monitor
                        Cotizaciones</span></a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" routerLink="/documents"><span class="nav-link-text">Documentos</span></a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="javascript:void(0);" data-bs-toggle="collapse" data-bs-target="#dash_task">
              <span class="nav-icon-wrap">
                <span class="svg-icon">
                  <fa-icon class="icon icon-tabler icon-tabler-message-dots icon-menu"
                    [icon]="faMagnifyingGlassChart"></fa-icon>
                </span>
              </span>
              <span class="nav-link-text">Informes</span>
              <span class="angle-down badge badge-sm badge-soft-pink ms-auto">
                <fa-icon class="icon icon-tabler icon-tabler-message-dots icon-menu-left"
                  [icon]="faAngleDown"></fa-icon>
              </span>
            </a>
            <ul id="dash_task" class="nav flex-column collapse nav-children">
              <li class="nav-item">
                <ul class="nav flex-column">
                  <li class="nav-item">
                    <a class="nav-link" routerLink="/reports">
                      <span class="nav-link-text">Reportes</span>
                    </a>
                  </li>
                  <!-- Ocultar Paneles -->
                  <!--
                    <li class="nav-item">
                      <a class="nav-link" routerLink="/paneles">
                        <span class="nav-link-text">Paneles</span>
                      </a>
                    </li>
                    -->
                  <!-- Ocultar Metas -->
                  <!--
                    <li class="nav-item">
                      <a class="nav-link" routerLink="/metas">
                        <span class="nav-link-text">Metas</span>
                      </a>
                    </li>
                    -->
                </ul>
              </li>
            </ul>

          </li>
          <!-- <li class="nav-item">
              <a class="nav-link" href="javascript:void(0);" data-bs-toggle="collapse" data-bs-target="#dash_blog">
                <span class="nav-icon-wrap">
                  <span class="svg-icon">
                    <fa-icon class="icon icon-tabler icon-tabler-message-dots icon-menu" [icon]="faMapMarker" ></fa-icon>
                  </span>
                </span>
                <span class="nav-link-text">Marketing</span>
                <span class="angle-down badge badge-sm badge-soft-pink ms-auto">
                  <fa-icon class="icon icon-tabler icon-tabler-message-dots icon-menu-left" [icon]="faAngleDown" ></fa-icon>
                </span>
              </a>
              <ul id="dash_blog" class="nav flex-column collapse  nav-children">
                <li class="nav-item">
                  <ul class="nav flex-column">
                    <li class="nav-item">
                      <a class="nav-link" routerLink="/campanias"><span class="nav-link-text">Campañas</span></a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" routerLink="/config_mkt"><span class="nav-link-text">Configuraciones</span></a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#"><span class="nav-link-text">Estadisticas</span></a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li> -->
          <li class="nav-item">
            <a class="nav-link" href="javascript:void(0);" data-bs-toggle="collapse"
              data-bs-target="#gestion_comercial">
              <span class="nav-icon-wrap">
                <span class="svg-icon">
                  <fa-icon class="icon icon-tabler icon-tabler-briefcase icon-menu" [icon]="faBriefcase"></fa-icon>
                </span>
              </span>
              <span class="nav-link-text">Gestión Comercial</span>
              <span class="angle-down badge badge-sm badge-soft-pink ms-auto">
                <fa-icon class="icon icon-tabler icon-tabler-chevron-down icon-menu-left"
                  [icon]="faAngleDown"></fa-icon>
              </span>
            </a>
            <ul id="gestion_comercial" class="nav flex-column collapse nav-children">
              <li class="nav-item">
                <a class="nav-link" routerLink="/rutas">
                  <span class="nav-link-text">Rutas</span>
                </a>
              </li>
            </ul>
          </li>



        </ul>
      </div>
    </div>
  </div>
  <!-- /Main Menu -->
</div>
