<div id="main-wrapper" class="oxyy-login-register">
  <div class="container-fluid px-0">
    <div class="row g-0 min-vh-100">
      <!-- Welcome Text -->
      <div class="col-md-6 col-lg-7 d-none d-md-flex align-items-center bg-blue">
        <div class="hero-content text-white text-center mx-auto">
          <h1>
            <img src="../../../assets/images/logos/logo_primary_font_blan.svg" alt="Bienvenido" class="img-fluid" style="width: 350px;">
          </h1>
          <img src="../../../assets/images/logos/medium-login.svg" alt="Bienvenido" class="img-fluid my-4" style="width: 400px;">
          <p class="lead mb-4 text-primary">La mejor manera de medir y administrar<br>las acciones comerciales</p>
        </div>
      </div>
      <!-- Login Form -->
      <div class="col-md-6 col-lg-5 d-flex align-items-center conten-form-login">
        <div class="container p-4">
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
              <h3 class="text-center mb-4 text-white-alpha-90">Accede a tu cuenta Clientyx</h3>
              <form [formGroup]="loginForm" (ngSubmit)="doLogin()" novalidate>
                <!-- Username -->
                <div class="mb-3">
                  <input
                    formControlName="usuario"
                    type="text"
                    class="form-control"
                    placeholder="Usuario"
                    required
                  />
                  <div *ngIf="loginForm.get('usuario')?.invalid && loginForm.get('usuario')?.touched" class="text-danger small">
                    Debe ingresar usuario.
                  </div>
                </div>
                <!-- Password -->
                <div class="mb-3">
                  <input
                    formControlName="password"
                    type="password"
                    class="form-control"
                    placeholder="Contraseña"
                    required
                  />
                  <div *ngIf="loginForm.get('password')?.invalid && loginForm.get('password')?.touched" class="text-danger small">
                    Debe ingresar la clave.
                  </div>
                </div>
                <!-- Links -->
                <div class="mb-1">
                  <a href="#" class="text-white-alpha-90 text-xs text-decoration-none">Recuperar contraseña</a>
                </div>
                <div class="mb-4">
                  <a href="#" class="underline text-blue-400 text-xs">Obtén tu demo ahora</a>
                </div>


                <!-- Submit button -->
                <div class="d-grid">
                  <button class="btn bg-secondary" type="submit" [disabled]="loading">
                    <i *ngIf="loading" class="fas fa-spinner fa-spin"></i>
                    <span *ngIf="!loading">Ingresar</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- Login Form End -->
    </div>
  </div>
</div>
