import { Component, ViewChild, ElementRef } from '@angular/core';
import { TabulatorFull } from 'tabulator-tables';
import { WsColmenaGeneralService } from 'src/app/providers/ws-colmena-general.service';
import { WsColmenaInformesService } from 'src/app/providers/ws-colmena-informes.service';
import { ChangeDetectorRef } from '@angular/core';
@Component({
  selector: 'app-cotizador',
  templateUrl: './cotizador.component.html',
  styleUrl: './cotizador.component.css',
})
export class CotizadorComponent {
  @ViewChild('tabulator', { static: true, read: ElementRef })
  tabulatorContainer!: ElementRef;
  table: any;

  public asesores: any = [];
  public asesorSelectChange: any;

  dates: Date[] | undefined;

  constructor(
    public ServicioGeneral: WsColmenaGeneralService,
    public WsColmenaInformesService: WsColmenaInformesService,
    private cd: ChangeDetectorRef
  ) {
    this.dates = [new Date()];
  }

  ngOnInit(): void {
    this.cargarInfoComboAsesores();
  }

  ngAfterViewInit(): void {
    this.iniciarTabulator();
  }

  cargarInfoComboAsesores() {
    const id_persona = localStorage.getItem('id_persona') || '0';
    const id_registro = localStorage.getItem('id_usuario') || '0';
    this.ServicioGeneral.ListComboAsesores(Number(localStorage.getItem('id_empresa')), id_persona,id_registro)
      .then((RespAsesores: any) => {
        this.asesores = [...RespAsesores];
        
        const existeAsesor = this.asesores.some((asesor: any) => asesor.value === id_persona);
        this.asesorSelectChange = existeAsesor ? id_persona : '0';

        this.cd.detectChanges(); // Forzar la detección de cambios.
      });
  }

  iniciarTabulator() {
    this.table = new TabulatorFull(this.tabulatorContainer.nativeElement, {
      layout: 'fitColumns',
      height: "311px",
      responsiveLayout: 'collapse',
      locale: 'es-es',
      addRowPos: 'top',
      movableColumns: true,
      resizableRows: true,
      columns: [
        { title: 'Producto', field: 'producto', editor: 'input' },
        { title: 'Cantidad', field: 'cantidad', editor: 'number' },
        {
          title: 'Precio Unitario',
          field: 'precio_unitario',
          editor: 'number',
        },
        {
          title: 'Subtotal',
          field: 'subtotal',
          editor: 'number',
          mutator: (
            value: any,
            data: { cantidad: any; precio_unitario: any },
            type: any,
            params: any,
            component: any
          ) => {
            return data.cantidad * data.precio_unitario;
          },
        },
      ],
    });

    // Simulación de agregar datos de productos
    this.table.setData([
      {
        producto: 'Producto 1',
        cantidad: 2,
        precio_unitario: 10.0,
        subtotal: 20.0,
      },
      {
        producto: 'Producto 2',
        cantidad: 1,
        precio_unitario: 15.0,
        subtotal: 15.0,
      },
    ]);
  }
}
