import { NgModule, Component } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';

import { MasterComponent } from './layouts/master/master.component';
import { SkeletonComponent } from './layouts/skeleton/skeleton.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { HeaderComponent } from './layouts/header/header.component';
import { MenuComponent } from '@layout/menu/menu.component';
import { MenuConfigComponent } from '@layout/menu-config/menu-config.component';

import { AppRoutingModule } from './app.routing';
import { Formio, FormioModule, FormioAppConfig } from '@formio/angular';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ComponentsModule } from './components/components.module';
import { EmailsComponent } from './pages/emails/emails.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormulariosComponent } from './pages/configuraciones/formularios/formularios.component';
import { WidgetsComponent } from './pages/integraciones/widgets/widgets.component';
import { PrimerContactoComponent } from './pages/gestion/primer-contacto/primer-contacto.component';
import { CalificadosComponent } from './pages/gestion/calificados/calificados.component';
import { PropuestasComponent } from './pages/gestion/propuestas/propuestas.component';
import { SeguimientoComponent } from './pages/gestion/seguimiento/seguimiento.component';
import { PostventaComponent } from './pages/gestion/postventa/postventa.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { DropzoneModule } from 'ngx-dropzone-wrapper';

import { faCoffee } from '@fortawesome/free-solid-svg-icons';

import { FormularioCreateComponent } from './components/formulario-create/formulario-create.component';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslationLoaderService } from './providers/TranslationLoaderService ';
import { PanelModule } from 'primeng/panel';
import { SplitButtonModule } from 'primeng/splitbutton';
import { GestionComercialModule } from './modules/gestion-comercial/gestion-comercial.module';
/*
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}*/

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    MasterComponent,
    SkeletonComponent,
    FooterComponent,
    HeaderComponent,
    MenuComponent,
    MenuConfigComponent,

    EmailsComponent,
    WidgetsComponent,
    PrimerContactoComponent,
    CalificadosComponent,
    PropuestasComponent,
    SeguimientoComponent,
    PostventaComponent,
    FormularioCreateComponent,
    
  ],
  imports: [
    PanelModule,
    BrowserModule,
    CommonModule,
    CoreModule,
    SharedModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ComponentsModule,
    //GoogleCallbackComponent,

    FormioModule,
    HttpClientModule,
    RouterModule,
    NgbModule,
    DropzoneModule,

    FontAwesomeModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SweetAlert2Module.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    SplitButtonModule,
    GestionComercialModule
  ],
  providers: [
    TranslationLoaderService,
    DatePipe,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  faCoffee = faCoffee;
  /*constructor(private translationLoaderService: TranslationLoaderService) {
    this.translationLoaderService.init().then(() => {
     
    });
  }*/
}
