import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RutasComponent } from './pages/rutas.component'; 

const routes: Routes = [{ path: 'rutas', component: RutasComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionComercialRoutingModule { }
