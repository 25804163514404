import { Component, OnInit } from '@angular/core';

interface RutaPunto {
  idRuta: number;
  ciudad: string;
  fecha: string;
  centroComercial: string;
  supervisor: number;   // ID del supervisor
  ubicacion: string;
  estado: string;
  notas: string[];
}

@Component({
  selector: 'app-rutas',
  templateUrl: './rutas.component.html',
  styleUrls: ['./rutas.component.css']
})
export class RutasComponent implements OnInit {

  // --- Estados y datos para modales ---
  displayRutaModal: boolean = false;
  displayNotasModal: boolean = false;
  displayGeoModal: boolean = false;

  editandoRuta: boolean = false;    // Para distinguir creación vs. edición

  // Formulario para crear/editar rutas/puntos
  rutaForm: any = {
    supervisor: null,
    ciudad: '',
    centroComercial: '',
    ubicacion: ''
  };

  // Para notas
  notasSeleccionadas: string[] = [];
  nuevaNota: string = '';

  // Para georreferencia
  puntoSeleccionado: any = null;

  // --- Modo de Visualización: Admin vs Supervisor ---
  modoSeleccionado: string = 'SUPERVISOR'; // Por defecto
  modosVisor = [
    { label: 'Ver Todo (Admin)', value: 'ADMIN' },
    { label: 'Supervisor',       value: 'SUPERVISOR' },
  ];
  supervisorSeleccionado: number | null = null;

  // --- Catálogo supervisores (simulado) ---
  supervisores = [
    { id: 1, nombre: 'Carlos Gómez' },
    { id: 2, nombre: 'María Dolores' },
    { id: 3, nombre: 'Raúl Pérez' },
  ];

  // --- Lista de rutas (simulada) ---
  rutas: any[] = []; // Cada elemento tiene {idRuta, supervisor, ciudad, fecha, puntos:[]}
  // Puntos filtrados para la tabla
  puntosFiltrados: RutaPunto[] = [];

  // --- Estados de las visitas ---
  estadosRuta = [
    { label: 'Pendiente',   value: 'PENDIENTE' },
    { label: 'En proceso',  value: 'EN_PROCESO' },
    { label: 'Completada',  value: 'COMPLETADA' },
  ];

  ngOnInit(): void {
    this.cargarDatosSimulados();
    // Filtra por defecto en modo SUPERVISOR
    this.onModoChange();
  }

  /**
   * Carga inicial de datos simulados
   */
  cargarDatosSimulados(): void {
    this.rutas = [
      {
        idRuta: 101,
        supervisor: 1,
        ciudad: 'Guayaquil',
        fecha: '2025-01-28',
        puntos: [
          {
            idPunto: 11,
            centroComercial: 'Mall del Sol',
            ubicacion: 'Av. Joaquín José Orrantia González',
            estado: 'PENDIENTE',
            notas: []
          },
          {
            idPunto: 12,
            centroComercial: 'CityMall',
            ubicacion: 'Av. Benjamín Carrión',
            estado: 'COMPLETADA',
            notas: ['Supervisión a las 10:00 am. Todo OK.']
          },
        ]
      },
      {
        idRuta: 102,
        supervisor: 1,
        ciudad: 'Daule',
        fecha: '2025-01-28',
        puntos: [
          {
            idPunto: 21,
            centroComercial: 'El Paseo Daule',
            ubicacion: 'Km 6.5 Vía Daule',
            estado: 'EN_PROCESO',
            notas: []
          }
        ]
      },
      {
        idRuta: 103,
        supervisor: 2,
        ciudad: 'Quito',
        fecha: '2025-01-29',
        puntos: [
          {
            idPunto: 31,
            centroComercial: 'Quicentro Sur',
            ubicacion: 'Av. Pedro Vicente Maldonado',
            estado: 'PENDIENTE',
            notas: []
          },
          {
            idPunto: 32,
            centroComercial: 'El Recreo',
            ubicacion: 'Av. Pedro Vicente Maldonado',
            estado: 'PENDIENTE',
            notas: []
          },
        ]
      },
      {
        idRuta: 104,
        supervisor: 3,
        ciudad: 'Manta',
        fecha: '2025-01-30',
        puntos: [
          {
            idPunto: 41,
            centroComercial: 'Mall del Pacífico',
            ubicacion: 'Malecón, Centro',
            estado: 'PENDIENTE',
            notas: []
          }
        ]
      },
    ];
  }

  /**
   * Cambia el modo de visualización (Admin / Supervisor)
   */
  onModoChange() {
    // Resetea si se escoge 'ADMIN'
    if (this.modoSeleccionado === 'ADMIN') {
      this.supervisorSeleccionado = null;
    }
    this.filtrarPuntos();
  }

  /**
   * Cuando cambio manualmente el supervisor
   */
  onSupervisorChange() {
    this.filtrarPuntos();
  }

  /**
   * Filtra la tabla según el modo y el supervisor (si aplica).
   */
  filtrarPuntos() {
    this.puntosFiltrados = [];

    if (this.modoSeleccionado === 'ADMIN') {
      // Muestra todo
      this.rutas.forEach((ruta) => {
        ruta.puntos.forEach((p: any) => {
          this.puntosFiltrados.push({
            ...p,
            idRuta: ruta.idRuta,
            fecha: ruta.fecha,
            ciudad: ruta.ciudad,
            supervisor: ruta.supervisor
          });
        });
      });
    } else {
      // MODO SUPERVISOR
      if (!this.supervisorSeleccionado) {
        this.puntosFiltrados = [];
        return;
      }
      // Filtra rutas del supervisor
      const rutasSupervisor = this.rutas.filter(
        r => r.supervisor === this.supervisorSeleccionado
      );
      rutasSupervisor.forEach(ruta => {
        ruta.puntos.forEach((p: any) => {
          this.puntosFiltrados.push({
            ...p,
            idRuta: ruta.idRuta,
            fecha: ruta.fecha,
            ciudad: ruta.ciudad,
            supervisor: ruta.supervisor
          });
        });
      });
    }
  }

  /**
   * Crear una nueva ruta/punto (modal)
   */
  crearNuevaRuta() {
    this.displayRutaModal = true;
    this.editandoRuta = false;
    this.rutaForm = {
      supervisor: null,
      ciudad: '',
      centroComercial: '',
      ubicacion: ''
    };
  }

  /**
   * Abrir modal para editar una ruta/punto
   */
  editarRuta(rowData: any) {
    this.displayRutaModal = true;
    this.editandoRuta = true;
    this.rutaForm = {
      supervisor: rowData.supervisor,
      ciudad: rowData.ciudad,
      centroComercial: rowData.centroComercial,
      ubicacion: rowData.ubicacion
    };
    this.puntoSeleccionado = rowData; 
  }

  /**
   * Guardar la creación/edición de la ruta/punto
   */
  guardarRuta() {
    if (this.editandoRuta) {
      // Actualizar data simulada
      this.puntoSeleccionado.supervisor = this.rutaForm.supervisor;
      this.puntoSeleccionado.ciudad = this.rutaForm.ciudad;
      this.puntoSeleccionado.centroComercial = this.rutaForm.centroComercial;
      this.puntoSeleccionado.ubicacion = this.rutaForm.ubicacion;
      // En una app real, habría un update a backend
    } else {
      // Creando uno nuevo
      const nuevoIdRuta = 999; // Valor simulado
      const punto = {
        idPunto: Math.floor(Math.random() * 10000),
        centroComercial: this.rutaForm.centroComercial,
        ubicacion: this.rutaForm.ubicacion,
        estado: 'PENDIENTE',
        notas: []
      };

      let rutaEncontrada = this.rutas.find(r => 
        r.supervisor === this.rutaForm.supervisor &&
        r.ciudad.toLowerCase() === this.rutaForm.ciudad.toLowerCase()
      );
      if (!rutaEncontrada) {
        rutaEncontrada = {
          idRuta: nuevoIdRuta,
          supervisor: this.rutaForm.supervisor,
          ciudad: this.rutaForm.ciudad,
          fecha: '2025-01-31',
          puntos: []
        };
        this.rutas.push(rutaEncontrada);
      }
      rutaEncontrada.puntos.push(punto);
    }
    this.displayRutaModal = false;
    this.filtrarPuntos();
  }

  /**
   * Eliminar ruta/punto
   */
  eliminarRuta(rowData: any) {
    const confirmDelete = confirm('¿Seguro que desea eliminar esta ruta/punto?');
    if (!confirmDelete) return;

    const rutaIndex = this.rutas.findIndex(r => r.idRuta === rowData.idRuta);
    if (rutaIndex >= 0) {
      const puntos = this.rutas[rutaIndex].puntos;
      const pIndex = puntos.findIndex((p: any) => p.idPunto === rowData.idPunto);
      if (pIndex >= 0) {
        puntos.splice(pIndex,1);
      }
      if (puntos.length === 0) {
        this.rutas.splice(rutaIndex,1);
      }
    }
    this.filtrarPuntos();
  }

  /**
   * Cambiar estado de un punto (Tabla)
   */
  cambiarEstado(punto: any, nuevoEstado: string) {
    punto.estado = nuevoEstado;
  }

  /**
   * Retorna el nombre del supervisor dado el ID
   */
  getSupervisorName(id: number): string {
    const sup = this.supervisores.find(s => s.id === id);
    return sup ? sup.nombre : 'Desconocido';
  }

  /**
   * Ver notas asociadas a un punto
   */
  verNotas(rowData: any) {
    this.puntoSeleccionado = rowData;
    this.notasSeleccionadas = rowData.notas || [];
    this.nuevaNota = '';
    this.displayNotasModal = true;
  }

  /**
   * Agregar nota a la lista de notas
   */
  agregarNota() {
    const notaLimpia = this.nuevaNota.trim();
    if (notaLimpia) {
      this.notasSeleccionadas.push(notaLimpia);
      this.puntoSeleccionado.notas = this.notasSeleccionadas;
      this.nuevaNota = '';
    }
  }

  /**
   * Modal de georreferencia (simulado)
   */
  abrirGeo(punto: any) {
    this.puntoSeleccionado = punto;
    this.displayGeoModal = true;
  }

  /**
   * Simular apertura de Google Maps con lat/lng
   */
  verEnMapa(punto: any) {
    const dummyLat = '-2.1901';
    const dummyLng = '-79.8899';
    window.open(`https://www.google.com/maps?q=${dummyLat},${dummyLng}`, '_blank');
  }

}
