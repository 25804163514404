<div class="container rutas-container">
  <div class="row mb-4">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">

          <!-- ENCABEZADO PRINCIPAL -->
          <h1 class="h4 mb-3 text-primary">Rutero Comercial</h1>
          <p class="section-description">
            <!-- Aquí puedes colocar un breve texto que explique la funcionalidad del módulo. -->
          </p>

          <!-- FILTROS DE VISUALIZACIÓN -->
          <div class="row mb-3">
            <!-- Modo: Admin / Supervisor -->
            <div class="col-md-4 mb-3">
              <label>Modo Visor</label>
              <p-dropdown 
                [options]="modosVisor" 
                optionLabel="label" 
                optionValue="value"
                [(ngModel)]="modoSeleccionado"
                (onChange)="onModoChange()"
                placeholder="Seleccione modo"
              >
              </p-dropdown>
            </div>

            <!-- Dropdown Supervisor (solo visible en modo Supervisor) -->
            <div class="col-md-4" *ngIf="modoSeleccionado === 'SUPERVISOR'">
              <label>Supervisor</label>
              <p-dropdown 
                [options]="supervisores" 
                optionLabel="nombre" 
                optionValue="id"
                [(ngModel)]="supervisorSeleccionado" 
                placeholder="Seleccione un supervisor"
                (onChange)="onSupervisorChange()"
              >
              </p-dropdown>
            </div>
          </div>

          <!-- BOTÓN NUEVA RUTA -->
          <div class="mb-3">
            <button 
              pButton 
              label="Nueva Ruta" 
              class="p-button-raised p-button-success"
              (click)="crearNuevaRuta()"
            ></button>
          </div>

          <!-- TABLA: Rutas/Puntos filtrados -->
          <p-table
            *ngIf="puntosFiltrados.length"
            [value]="puntosFiltrados"
            [paginator]="true"
            [rows]="5"
            sortField="fecha"
            [sortOrder]="1"
          >
            <ng-template pTemplate="header">
              <tr>
                <th>Ruta</th>
                <th>Ciudad</th>
                <th>Centro Comercial</th>
                <th>Supervisor</th>
                <th>Ubicación</th>
                <th>Estado</th>
                <th>Notas</th>
                <th>Acciones</th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td>{{ rowData.idRuta }}</td>
                <td>{{ rowData.ciudad }}</td>
                <td>{{ rowData.centroComercial }}</td>
                <td>{{ getSupervisorName(rowData.supervisor) }}</td>
                <td>{{ rowData.ubicacion }}</td>

                <!-- Estado editable -->
                <td>
                  <p-dropdown
                    [options]="estadosRuta"
                    optionLabel="label"
                    optionValue="value"
                    [(ngModel)]="rowData.estado"
                    (onChange)="cambiarEstado(rowData, $event.value)"
                  ></p-dropdown>
                </td>

                <!-- Botón para ver notas -->
                <td>
                  <button 
                    pButton 
                    type="button" 
                    icon="pi pi-eye"
                    class="p-button-text p-button-sm"
                    (click)="verNotas(rowData)"
                  ></button>
                </td>

                <!-- Íconos de acciones (Editar/Eliminar/Geo) -->
                <td>
                  <!-- Editar Ruta -->
                  <button 
                    pButton 
                    class="p-button-rounded p-button-info p-button-sm" 
                    icon="pi pi-pencil" 
                    style="margin-right:5px"
                    (click)="editarRuta(rowData)"
                    title="Editar Ruta"
                  ></button>

                  <!-- Eliminar Ruta -->
                  <button 
                    pButton 
                    class="p-button-rounded p-button-danger p-button-sm" 
                    icon="pi pi-trash" 
                    style="margin-right:5px"
                    (click)="eliminarRuta(rowData)"
                    title="Eliminar Ruta"
                  ></button>

                  <!-- Ver georreferencia -->
                  <button 
                    pButton 
                    class="p-button-rounded p-button-warning p-button-sm" 
                    icon="pi pi-map-marker"
                    (click)="abrirGeo(rowData)"
                    title="Ver Georreferencia"
                  ></button>
                </td>
              </tr>
            </ng-template>
          </p-table>

          <!-- Mensaje si no hay datos -->
          <div *ngIf="!puntosFiltrados.length" class="mt-3 text-muted">
            No hay rutas asignadas.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MODAL: Nueva/Editar Ruta -->
<p-dialog
  header="{{ editandoRuta ? 'Editar Ruta' : 'Nueva Ruta' }}"
  [(visible)]="displayRutaModal"
  [modal]="true"
  [style]="{width: '50vw'}"
>
  <div class="p-fluid">
    <div class="field">
      <label>Supervisor</label>
      <p-dropdown
        [options]="supervisores"
        optionLabel="nombre"
        optionValue="id"
        [(ngModel)]="rutaForm.supervisor"
        placeholder="Seleccione un supervisor"
      ></p-dropdown>
    </div>
    <div class="field">
      <label>Ciudad</label>
      <input pInputText [(ngModel)]="rutaForm.ciudad" />
    </div>
    <div class="field">
      <label>Centro Comercial</label>
      <input pInputText [(ngModel)]="rutaForm.centroComercial" />
    </div>
    <div class="field">
      <label>Ubicación</label>
      <input pInputText [(ngModel)]="rutaForm.ubicacion" />
    </div>
  </div>

  <p-footer>
    <button pButton label="Guardar" class="p-button-primary" (click)="guardarRuta()"></button>
    <button pButton label="Cancelar" class="p-button-text" (click)="displayRutaModal=false"></button>
  </p-footer>
</p-dialog>

<!-- MODAL: Ver Notas -->
<p-dialog
  header="Notas"
  [(visible)]="displayNotasModal"
  [modal]="true"
  [style]="{width: '40vw'}"
>
  <div class="p-fluid" style="max-height: 300px; overflow:auto;">
    <ul class="list-group">
      <li
        class="list-group-item"
        *ngFor="let nota of notasSeleccionadas; let i = index"
      >
        {{ nota }}
      </li>
    </ul>
    <div class="field mt-3">
      <label for="notaNueva">Nueva Nota</label>
      <textarea
        id="notaNueva"
        rows="3"
        class="p-inputtextarea p-component"
        [(ngModel)]="nuevaNota"
      ></textarea>
    </div>
  </div>

  <p-footer>
    <button
      pButton
      label="Añadir Nota"
      class="p-button-primary"
      (click)="agregarNota()"
    ></button>
    <button
      pButton
      label="Cerrar"
      class="p-button-text"
      (click)="displayNotasModal=false"
    ></button>
  </p-footer>
</p-dialog>

<!-- MODAL: Georreferencia -->
<p-dialog
  header="Georreferencia"
  [(visible)]="displayGeoModal"
  [modal]="true"
  [style]="{width: '40vw'}"
>
  <div class="p-fluid geo-dialog-content">
    <p class="text-muted">Registro de georreferencia</p>
    <p>
      <strong class="text-primary">Nota creada el 14/01/2025 15:30</strong><br>
      {{ puntoSeleccionado?.centroComercial }} - {{ puntoSeleccionado?.ciudad }}
    </p>

    <div class="text-center p-3" style="border:1px dashed #ccc;">
      <p>Lat: -2.1901 &nbsp; / &nbsp; Lng: -79.8899</p>
      <button
        pButton
        label="Ver Ubicación"
        class="p-button-raised p-button-warning mt-2"
        (click)="verEnMapa(puntoSeleccionado)"
      ></button>
    </div>
  </div>

  <p-footer>
    <button
      pButton
      label="Cerrar"
      class="p-button-text"
      (click)="displayGeoModal=false"
    ></button>
  </p-footer>
</p-dialog>
